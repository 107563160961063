<template>
  <div>
    <validation-observer ref="forgotPasswordForm">
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="validationForm"
      >
        <b-form-group
          :label="$t('auth.form.email')"
          label-for="userEmail"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.form.email')"
            rules="required|email"
            vid="userEmail"
          >
            <b-form-input
              id="userEmail"
              v-model="userEmail"
              :state="errors.length > 0 ? false:null"
              name="userEmail"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          block
        >
          {{ $t('auth.forgotPassword.sendResetLink') }}
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <b-link :to="{name:'auth-login'}">
        <feather-icon icon="ChevronLeftIcon" /> {{ $t('auth.forgotPassword.backToLogin') }}
      </b-link>
    </p>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) { this.doSendRequest() }
      })
    },

    doSendRequest() {
      this.$http.post('/forget', {
        email: this.userEmail,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('auth.forgotPassword.emailSentToast.title'),
              icon: 'CoffeeIcon',
              variant: 'success',
              text: this.$t('auth.forgotPassword.emailSentToast.body'),
            },
          })
        })
        .catch(() => {
          this.$refs.forgotPasswordForm.setErrors({
            userEmail: [this.$t('auth.forgotPassword.verifyEmail')],
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
